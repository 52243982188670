import React from "react";

import "./news/Article.scss";
import Layout from "../components/Layout/Layout";

const Privacy = () => {
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">PRIVACY</h1>
            <p>
              Data protection and data security are of enormous importance to
              S&T Mold. We attach great importance to the protection of personal
              rights and respect the protection of your personal data. We are
              therefore constantly working to improve the measures to protect
              your data. With this data protection notice we explain what
              information we collect during your visit to our website and how it
              is used. It does not apply to websites of companies that do not
              belong to S&T and which may have a link to this or another S&T
              website or to which a link from our website leads.
            </p>
            <p className="bold">Collection and processing of personal data</p>
            <p>
              When you visit our website, our web server temporarily records the
              domain name or IP address of the requesting computer as well as
              the access date, the file request of the client (file name and
              URL), the HTTP response code and the website for the purpose of
              system security from which you are visiting us and the number of
              bytes transferred during the connection. We may save some
              information in the form of so-called cookies on your PC. A cookie
              is an element of data that a website can send to your browser and
              store on your system. We have no access to your system or your
              personal data via a cookie. However, we would like to point out
              that the standard browser setting allows the use of cookies. If
              you do not want the use of cookies, you can switch this off on
              your browser. We only use cookies to optimize our website
              according to your preferences. Personal data such as your name,
              your address, your telephone number or your e-mail address are not
              recorded unless you provide this information voluntarily, e.g. B.
              in the context of an information request or an application /
              information on an advertised position, etc.{" "}
            </p>
            <p className="bold">Use of Google Analytics</p>
            <p>
              This website uses Google Analytics, a web analysis service from
              Google Inc. (“Google”). Google Analytics uses so-called "cookies",
              text files that are stored on your computer and that enable your
              use of the website to be analyzed. The information generated by
              the cookie about your use of this website is usually transferred
              to a Google server in the USA and stored there. If IP
              anonymization is activated on this website, your IP address will
              be shortened beforehand by Google within member states of the
              European Union or in other contracting states of the Agreement on
              the European Economic Area. The full IP address will only be
              transmitted to a Google server in the USA and shortened there in
              exceptional cases. On behalf of the operator of this website,
              Google will use this information to evaluate your use of the
              website, to compile reports on website activity and to provide the
              website operator with other services relating to website activity
              and internet usage. The IP address transmitted by your browser as
              part of Google Analytics will not be merged with other Google
              data. You can prevent the storage of cookies by setting your
              browser software accordingly; we would like to point out, however,
              that in this case you may not be able to use all functions of this
              website to their full extent. You can also prevent the data
              generated by the cookie and related to your use of the website
              (incl. Your IP address) to Google and the processing of this data
              by Google by downloading and installing the browser plug-in
              available under the following{" "}
              <a href="http://tools.google.com/dlpage/gaoptout?hl=de">Link</a>{" "}
               Especially for browsers on mobile devices, please click this link
              in order to prevent the anonymized collection of data by Google
              Analytics on this website for your browser by means of a so-called
              "opt-out cookie". You can find more information on terms of use
              and data protection at{" "}
              <a href="http://www.google.com/analytics/terms/de.html">Link</a>{" "}
              or at <a href="https://www.google.de/intl/de/policies/">Link</a>{" "}
               Especially for browsers on mobile devices, please click this link
              in order to prevent the anonymized collection of data by Google
              Analytics on this website for your browser by means of a so-called
              "opt-out cookie". You can find more information on terms of use
              and data protection at{" "}
              <a href="http://www.google.com/analytics/terms/de.html">Link</a>{" "}
              or at <a href="https://www.google.de/intl/de/policies/">Link</a>{" "}
              Especially for browsers on mobile devices, please click this link
              in order to prevent the anonymized collection of data by Google
              Analytics on this website for your browser by means of a so-called
              "opt-out cookie". You can find more information on terms of use
              and data protection at{" "}
              <a href="http://www.google.com/analytics/terms/de.html">Link</a>{" "}
              or at <a href="https://www.google.de/intl/de/policies/">Link</a>
            </p>
            <p classNaame="bold">Registration on our website</p>
            <p>
              The data subject has the option of registering on the website by
              providing personal data. Which personal data results from the
              respective input mask that is used for the registration. The
              personal data entered by the data subject is collected and stored
              exclusively for internal use and for our own purposes. The person
              responsible for the processing can arrange for the transfer to be
              made to one or more processors, for example a parcel service
              provider, who also uses the personal data exclusively for internal
              use attributable to the person responsible for the processing. By
              registering on the website of the person responsible for
              processing, the IP address assigned by the Internet service
              provider (ISP) to the person concerned, the date and time of
              registration are also saved. This data is stored against the
              background that this is the only way to prevent misuse of our
              services and, if necessary, to enable criminal offenses to be
              investigated. In this respect, the storage of this data is
              necessary to protect the person responsible for processing. This
              data is generally not passed on to third parties unless there is a
              legal obligation to pass it on or it is used for criminal
              prosecution. The registration of the data subject with the
              voluntary provision of personal data enables the data controller
              to offer the data subject content or services which, due to the
              nature of the matter, can only be offered to registered users.{" "}
            </p>
            <p className="bold">Contact option via this website</p>
            <p>
              This website contains information that enables quick electronic
              contact with our company and direct communication with us. If a
              data subject contacts the person responsible for processing by
              email or a contact form, the personal data transmitted by the data
              subject will be automatically saved. Such personal data
              transmitted on a voluntary basis by a data subject to the person
              responsible for processing are stored for the purposes of
              processing or contacting the data subject. This personal data is
              not passed on to third parties.{" "}
            </p>
            <p className="bold">Newsletter</p>
            <p>
              By subscribing to the newsletter, you agree that all of the data
              provided may be processed by S&T Mold for advertising purposes in
              the course of sending the newsletter. You can revoke this consent
              at any time and without giving reasons by clicking the unsubscribe
              link in every newsletter. If you want to change your data, you can
              use the corresponding change data link in the newsletter. We use
              the email marketing software mailworx to send and analyze our
              newsletters. mailworx records the opening and clicking
              behavior. Specifically, the following information is tracked: time
              of delivery, time of opening, duration of opening, IP address of
              opening, e-mail program used (mail client), which link was clicked
              and the time of the click. These data are processed exclusively
              within the European Union and are not passed on to third parties.{" "}
            </p>
            <p className="bold">Security</p>
            <p>
              We have taken extensive, state-of-the-art, technical and
              organizational measures to protect the personal data we hold about
              you from unauthorized access and misuse.{" "}
            </p>

            <p className="bold">Contacts</p>
            <p>S&T MOLD SRL</p>
            <p>8 Сalea Iesilor Str.</p>
            <p>t: + 373 22 837 960</p>
            <p>snt@snt.md </p>
            <p className="bold">Rights of the data subject</p>
            <p>
              For questions and suggestions or specific requests such as
              confirmation (right to confirmation), information (right to
              information), change (right to correction), blocking or deletion
              (right to be forgotten) of your personal data, please contact S&T
              Mold:  S&T Mold SRL T: +373 837 960 Email: snt@snt.md
            </p>
            <p className="bold">Disclaimer of liability</p>
            <p>
              S&T does not guarantee that the information provided on this
              website is complete, correct and always up-to-date. This also
              applies to all connections ("links") to which this website refers
              directly or indirectly. S&T is not responsible for the content of
              a page reached via such a link. S&T reserves the right to make
              changes or additions to the information provided without prior
              notice. By providing this information, S&T does not justify a
              contractual offer for information, advice or similar contractual
              relationships. Any liability for the use of the content of the
              website or the correctness of the content or the accessibility of
              the website is excluded. 
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Privacy;
